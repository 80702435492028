import environment from '../../../../environments/environment';

export class BaseAvatar {
    name: string;

    // profile picture for user
    userId: number;

    profileImagePath: string;
    profileImage: string;

    constructor(protected data: { [prop: string]: any }) {
        if (data.name) {
            this.name = data.name;
        }

        if (data.userId) {
            this.userId = data.userId;
            this.validateProfileImage(`${environment.S3URL + environment.UserProfileBucket}/${this.userId}/profile-150.png`);
        }
    }

    get initials(): string {
        const parts = this.name.split(' ').map((p) => {
            return p.split(/(?=[A-Z])/).filter((n) => {
                const initial = n.charAt(0);
                return !isNaN(+initial) || initial === initial.toUpperCase();
            });
        });
        const flattened = parts.reduce((a, b) => a.concat(b), []);
        return flattened[0].charAt(0) + (flattened[1] ? flattened[1].charAt(0) : '');
    }

    private validateProfileImage(path: string) {
        if (!path) {
            return null;
        }

        const img = new Image();

        img.onload = () => {
            this.profileImage = `${path}?nocache=${new Date().getTime()}`;
        };

        img.onerror = () => {
            this.profileImage = null;
        };

        img.src = path;
    }
}
