import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { PropertyManagementCompany } from '../../../../models/entities/property-management-company';
import { PropertyManagementCompanyDataService } from '../../../../services/property-management-company/property-manager-company-data.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';

@UntilDestroy()
@Component({
    selector: 'app-sidenav-navigation',
    templateUrl: './sidenav-navigation.component.html',
    styleUrls: ['./sidenav-navigation.component.scss'],
})
export class SidenavNavigationComponent implements OnInit
{
    showNavigation = false;
    pmc$: Observable<PropertyManagementCompany>;
    jwt: JwtLegFiClaims;

    constructor(
            private _propertyManagementCompanyData: PropertyManagementCompanyDataService,
            private _activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.pmc$ = this._propertyManagementCompanyData.pmc$;
        this.jwt = LegFiJwtService.read();

        this._activatedRoute.paramMap.pipe(
                map((params) => +params.get('pmcId')),
                filter((pmcId) => !!pmcId),
                untilDestroyed(this),
        ).subscribe({
            next: () => this.showNavigation = true,
        });
    }
}
