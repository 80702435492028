import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Activity } from './activity';
import { Category } from './accounting/category';
import { DepositBankAccount } from './deposit-bank-account';
import { Membership } from './membership';
import { Organization } from './organization';
import { PaymentItem } from './payment-item';
import { User } from './user';
import { ChargeLateFee } from './charge-late-fee';
import { Unit } from './unit';
import { Note } from './note';
import Moment = moment.Moment;

export class Charge
{
    id: number;
    organizationId: number;
    parentId: number;
    payorId: number;
    payorType: string;
    depositBankAccountId: number;
    categoryId: number;
    title: string;
    description: string;
    emailAppendMessage: string;
    quantity: number;
    currency: string;
    chargeAmount: number;
    paymentsSum: number;
    balance: number;
    paid: boolean;
    activeAfter: any;
    paymentDueOn: Moment;
    earlyBefore: any;
    earlyDiscount: number;
    lateAfter: any;
    lateFee: number;
    expectedTotal: number = 0;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    lastActivity: Activity;
    category: Category;
    depositBankAccount: DepositBankAccount;
    organization: Organization;
    payor: Membership | Organization | User | Unit;
    payments: PaymentItem;

    displayName: string;
    familyName: string;
    givenNames: string;
    accountDisplayName: string;
    name: string;

    ownerId: number;
    ownerType: string;
    early: boolean;
    late: boolean;
    status: string;
    daysOverdue: number;
    paymentAmount: number;

    reason: string;
    // When true, Charge is meant to be added to DOM but not rendered.
    hidden: boolean;
    checked: boolean;
    showDetails: boolean;
    autopayEnabled: boolean;

    removeLateFees: boolean;
    removeEarlyDiscounts: boolean;
    emailInvoice: boolean;
    lateFees: ChargeLateFee[] = [];
    children: Charge[] = [];
    childrenCount = 0;
    notes: Note[] = [];
    history: {
        paymentItemId?: number
        paymentMethodType?: string,
        activityType: string,
        activityDate: Moment,
        charge: number,
        payment: number,
        balance: number
    }[] = [];

    constructor(charge: any) {
        if (charge.id) {
            this.id = charge.id;
        }
        if (charge.children) {
            this.children = charge.children.map(child => new Charge(child));
        }
        if (charge.parentId) {
            this.parentId = charge.parentId;
        }
        if (charge.expectedTotal) {
            this.expectedTotal = charge.expectedTotal;
        }
        if (charge.organizationId) {
            this.organizationId = charge.organizationId;
        }
        if (charge.payorId) {
            this.payorId = charge.payorId;
        }
        if (charge.payorType) {
            this.payorType = charge.payorType;
        }
        if (charge.name) {
            this.name = charge.name;
        }
        if (charge.depositBankAccountId) {
            this.depositBankAccountId = charge.depositBankAccountId;
        }
        if (charge.categoryId) {
            this.categoryId = charge.categoryId;
        }
        if (charge.title) {
            this.title = charge.title;
        }
        if (charge.description) {
            this.description = charge.description;
        }
        if (charge.emailAppendMessage) {
            this.emailAppendMessage = charge.emailAppendMessage;
        }
        if (charge.quantity) {
            this.quantity = charge.quantity;
        }
        if (charge.currency) {
            this.currency = charge.currency;
            this.currency = this.currency.toLocaleUpperCase();
        }
        this.chargeAmount = charge.chargeAmount || 0;
        this.paymentsSum = charge.paymentsSum || 0;
        this.balance = charge.balance || 0;
        if (charge.paid) {
            this.paid = charge.paid;
        } else {
            this.paid = false;
        }
        if (charge.earlyDiscount) {
            this.earlyDiscount = charge.earlyDiscount;
        }
        if (charge.lateFee) {
            this.lateFee = charge.lateFee;
        }

        const timezone = LegFiJwtService.getTimezone();

        if (charge.activeAfter) {
            this.activeAfter = moment.utc(charge.activeAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (charge.paymentDueOn) {
            this.paymentDueOn = moment.utc(charge.paymentDueOn, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (charge.earlyBefore) {
            this.earlyBefore = moment.utc(charge.earlyBefore, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (charge.lateAfter) {
            this.lateAfter = moment.utc(charge.lateAfter, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
            this.late = moment().tz(timezone).isAfter(this.lateAfter);
        }
        if (charge.createdAt) {
            this.createdAt = moment.utc(charge.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (charge.updatedAt) {
            this.updatedAt = moment.utc(charge.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (charge.deletedAt) {
            this.deletedAt = moment.utc(charge.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (charge.history) {
            this.history = charge.history.map((historyItem) => {
                return {
                    ...historyItem,
                    activityDate: moment.utc(historyItem.activityDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone),
                };
            });
        }

        if (charge.lastActivity) {
            this.lastActivity = new Activity(charge.lastActivity);
        }
        if (charge.category) {
            this.category = new Category(charge.category);
        }
        if (charge.accountDisplayName) {
            this.accountDisplayName = charge.accountDisplayName;
        }
        if (charge.depositBankAccount) {
            this.depositBankAccount = new DepositBankAccount(charge.depositBankAccount);
        }
        if (charge.organization) {
            this.organization = new Organization(charge.organization);
        }
        if (charge.payor && charge.payorType !== null) {
            if (charge.payorType.indexOf('Membership') !== -1) {
                this.payor = new Membership(charge.payor);
            } else if (charge.payorType.indexOf('Organization') !== -1) {
                this.payor = new Organization(charge.payor);
            } else if (charge.payorType.indexOf('User') !== -1) {
                this.payor = new User(charge.payor);
            } else if (charge.payorType.indexOf('Unit') !== -1) {
                this.payor = new Unit(charge.payor);
            }
        }
        if (charge.payments) {
            this.payments = charge.payments.map((paymentItem) => {
                return new PaymentItem(paymentItem);
            });
        }
        if (charge.childrenCount) {
            this.childrenCount = charge.childrenCount;
        }

        const now = moment();
        this.status = 'paid';
        if (this.deletedAt) {
            this.status = 'deleted';
        } else if (!this.paid) {
            if (this.paymentDueOn < now) {
                this.status = 'overdue';
                this.daysOverdue = now.diff(this.paymentDueOn, 'days');
            } else if (this.activeAfter > now) {
                this.status = 'future';
            } else if (this.paymentsSum > 0) {
                this.status = 'partial';
            } else {
                this.status = 'current';
            }
        }

        if (now < this.earlyBefore) {
            this.early = true;
        }

        if (now > this.lateAfter || this.childrenCount > 0) {
            this.late = true;
        }

        this.autopayEnabled = charge.autopayEnabled || false;

        if (charge.hasOwnProperty('emailInvoice')) {
            this.emailInvoice = charge.emailInvoice;
        }
        if (charge.lateFees) {
            this.lateFees = charge.lateFees.map((fee) => {
                return new ChargeLateFee(fee);
            });
        }
        if (charge.notes) {
            this.notes = charge.notes.map(note => new Note(note));
        }
    }
}
