import moment, { Moment } from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';

export class FeatureFlag
{
    id: number;
    name: string;
    featureName: string;
    description: string;
    organizationId: number;
    propertyManagementCompanyId: number;
    createdAt: Moment;

    constructor(ff: any) {
        this.id = ff.id;
        this.name = ff.name;
        this.featureName = ff.featureName;
        this.description = ff.description;
        this.organizationId = ff.organizationId;
        this.propertyManagementCompanyId = ff.propertyManagementCompanyId;

        const timezone = LegFiJwtService.getTimezone();
        this.createdAt = moment.utc(ff.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
    }
}
