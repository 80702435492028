import { ViewTab } from '../models/entities/view-tab';
import { RoutedTab } from '../models/entities/routed-tab';
import { CallbackTab } from '../models/entities/callback-tab';
import { UnitDashboardKeys } from '../enums/unit-dashboard-keys.enum';

export class Tabs
{
    static PeopleListTabs: CallbackTab[] = [
        {
            active: false,
            status: 'active',
            label: 'Active',
        },
        {
            active: false,
            status: 'archived',
            label: 'Archived',
        },
        {
            active: false,
            status: 'unconfirmed',
            label: 'New Registrations',
        },
        {
            active: false,
            status: 'directory',
            label: 'Opt-In Directory',
            secondary: true,
            feature: 'membership-directory.enabled',
            module: 'membershipDirectoryModuleEnabled',
        },
    ];

    static PayableTabs: RoutedTab[] = [
        {
            active: true,
            routerLink: ['/app/payables/inbox'],
            routeName: 'Inbox',
        },
        {
            active: false,
            routerLink: ['/app/payables/unpaid'],
            routeName: 'Unpaid',
        },
        {
            active: false,
            routerLink: ['/app/payables/paid'],
            routeName: 'Paid',
        },
        {
            active: false,
            routerLink: ['/app/payables/audits'],
            routeName: 'Audits',
            secondary: true,
            admin: true,
            super: true,
        },
        {
            active: false,
            routerLink: ['/app/payables/approval-rules'],
            routeName: 'Approval Rules',
            secondary: true,
            admin: true,
        },
    ];

    static PayableFormTabs: CallbackTab[] = [
        {
            active: true,
            disabled: false,
            status: 'upload',
            label: 'Upload',
        },
        {
            active: false,
            disabled: false,
            status: 'invoice',
            label: 'Invoice',
        },
        {
            active: false,
            disabled: false,
            status: 'attachments',
            label: 'Other Documents',
        },
    ];

    static LegacyBillPayTabs: RoutedTab[] = [
        {
            active: false,
            routerLink: ['/app/bill-pay/vendors'],
            routeName: 'Vendor List',
            secondary: false,
        },
        {
            active: false,
            routerLink: ['/app/bill-pay/dashboard'],
            routeName: 'Payment History',
            secondary: false,
        },
    ];

    static VendorTabs: RoutedTab[] = [
        {
            active: true,
            routerLink: ['transaction-history'],
            routeName: 'Transaction History',
        },
        // {
        //     active: false,
        //     routerLink: ['unpaid-invoices'],
        //     routeName: 'Unpaid Invoices',
        // },
        {
            active: false,
            routerLink: ['documents'],
            routeName: 'Documents',
        },
        {
            active: false,
            routerLink: ['notes'],
            routeName: 'Notes',
        },
    ];

    static CheckPrintingTabs: CallbackTab[] = [
        {
            active: false,
            status: 'outstanding',
            label: 'Outstanding',
        },
        {
            active: false,
            status: 'voided',
            label: 'Voided',
        },
    ];

    /**
     * Default tab set for User settings group of views.
     * @type {{active: boolean, routerLink: string[], routeName: string}[]}
     */
    static UserSettingsTabs: RoutedTab[] = [
        {
            active: false,
            routerLink: ['/app/settings/user'],
            routeName: 'User Settings',
        },
        {
            active: false,
            routerLink: ['/app/settings/user/financial-accounts'],
            routeName: 'Wallet',
        },
        {
            active: false,
            routerLink: ['/app/settings/user/security'],
            routeName: 'Security',
        },
        {
            active: false,
            routerLink: ['/app/settings/user/notifications'],
            routeName: 'Notifications',
        },
        {
            active: false,
            routerLink: ['/app/settings/user/autopay'],
            routeName: 'Autopay',
        },
    ];

    /**
     * @type {{active: boolean, routerLink: any, routeName: string}[]}
     */
    static UnitCommTabs: CallbackTab[] = [
        {
            active: true,
            status: 'units',
            label: 'Select Units',
        },
        {
            active: false,
            status: 'owners',
            label: 'Select Owners',
        },
    ];

    /**
     * Default tab set for filtering Charges
     * @type {{active: boolean, routerLink: any, routeName: string}[]}
     */
    static OrgChargeFilterTabs: CallbackTab[] = [
        {
            active: false,
            status: 'unpaid',
            label: 'Outstanding Invoices',
        },
        {
            active: false,
            status: 'all',
            label: 'All Invoices',
        },
        {
            active: false,
            status: 'deleted',
            label: 'Deleted Invoices',
        },
        {
            active: false,
            status: 'recurring',
            label: 'Recurring Templates',
        },
    ];

    /**
     * Tabs on the Reports page, toggle between individual and report packets
     */
    static ReportsTabs: RoutedTab[] = [
        {
            active: true,
            routerLink: ['/app/reports'],
            routeName: 'Reports',
        },
        {
            active: false,
            routerLink: ['/app/reports/packets'],
            routeName: 'Report Packets',
        },
    ];

    /**
     * Mat Tabs for Unit Dashboard
     */
    static UnitDashboardTabs: ViewTab[] = [
        {id: UnitDashboardKeys.DETAILS, name: 'details', label: 'Unit Details', visible: true},
        {id: UnitDashboardKeys.COMMUNICATIONS, name: 'communications', label: 'Communications', visible: false},
        {id: UnitDashboardKeys.DOCUMENTS, name: 'documents', label: 'Documents', visible: true},
        {id: UnitDashboardKeys.REQUESTS, name: 'requests', label: 'Requests', visible: false},
        {id: UnitDashboardKeys.VIOLATIONS, name: 'violations', label: 'Violations', visible: false},
    ];

    /**
     * Mat Tabs for Report Packet Details
     */
    static ReportPacketTabs: ViewTab[] = [
        {id: 0, name: 'saved', label: 'Saved Packets'},
        {id: 1, name: 'edit', label: 'Packet Editor'},
        {id: 2, name: 'criteria', label: 'Report Criteria'},
    ];

    /**
     * Mat Tabs for Request Form Settings
     */
    static RequestFormTabs: ViewTab[] = [
        {id: 0, name: 'notifications', label: 'Notifications & Permissions'},
        {id: 1, name: 'edit', label: 'Form Editor'},
        {id: 2, name: 'statuses', label: 'Statuses'},
    ];

    /**
     * Mat Tabs for Reconciliations
     */
    static ReconciliationsTabs: ViewTab[] = [
        {id: 0, name: 'all', label: 'All Transactions'},
        {id: 1, name: 'payments', label: 'Payments'},
        {id: 2, name: 'deposits', label: 'Deposits'},
    ];

    /**
     * Default tab set for ProfitVsLoss Reports, toggle between Cash Flow and Invoiced/Pd graphs
     */
    static ProfitVsLoss: CallbackTab[] = [
        {
            active: false,
            color: '#aed760',
            status: 'cash',
            label: 'Cash-based',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'accrual',
            label: 'Accrual-based',
        },
    ];

    /** Tabs on the Broadcast detail page */
    static BroadcastListCategoryTabs: CallbackTab[] = [
        {
            active: false,
            color: '#aed760',
            status: 'group',
            label: 'Group Communications',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'inquiries',
            label: 'Requests',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'violations',
            label: 'Violations',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'receipts',
            label: 'Invoices & Receipts',
        },
    ];

    /** Other tabs on the Broadcast detail page */
    static BroadcastListStatusTabs: CallbackTab[] = [
        {
            active: false,
            color: '#ff9933',
            status: 'sent',
            label: 'Sent',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'opened',
            label: 'Opened',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'unopened',
            label: 'Unopened',
        },
        {
            active: false,
            color: '#ff9933',
            status: 'undeliverable',
            label: 'Undeliverable',
        },
    ];

    /** Tabs on the communications surveys page */
    static SurveysTabs: CallbackTab[] = [
        {
            active: false,
            status: 'Active',
            label: 'Active',
        },
        {
            active: false,
            status: 'Closed',
            label: 'Closed',
        },
    ];

    /**
     * Tab used when with composing messages.
     */
    static ComposeTab: CallbackTab[] = [
        {
            active: true,
            status: 'message',
            label: 'Compose Message',
        },
    ];

    /**
     * Default tab set for Org settings group of views.
     * @type {{active: boolean, routerLink: string[], routeName: string}[]}
     */
    static OrgPayHOASettingsTabs: RoutedTab[] = [
        {
            active: false,
            routerLink: ['/app/settings/organization'],
            routeName: 'Organization Settings',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/preferences'],
            routeName: 'Preferences',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/billing'],
            routeName: 'Billing',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/financial-accounts'],
            routeName: 'Bank Accounts',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/permissions'],
            routeName: 'Permissions',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/manage-tags'],
            routeName: 'Tags',
        },
        {
            active: false,
            routerLink: ['/app/settings/organization/manage-custom-fields'],
            routeName: 'Custom Fields',
        },
        {
            active: false,
            super: true,
            routerLink: ['/app/settings/organization/feature-flags'],
            routeName: 'Feature Flags',
        },
    ];

    static CreateUnitTabs: RoutedTab[] = [
        {
            active: false,
            routerLink: ['/app/unit/create'],
            routeName: 'Add Single Unit',
        },
        {
            active: false,
            routerLink: ['/app/owner/create'],
            routeName: 'Add Single Owner',
        },
        {
            active: false,
            routerLink: ['/app/unit/bulk-importer'],
            routeName: 'Import Owners and Units',
        },
    ];

    /**
     * Transactions tabs
     */
    static TransactionTabs: CallbackTab[] = [
        {
            active: false,
            status: 'unreviewed',
            label: 'For Review',
        },
        {
            active: false,
            status: 'reviewed',
            label: 'Reviewed',
        },
        {
            active: false,
            status: 'all',
            label: 'All Transactions',
        },
        {
            active: false,
            status: 'excluded',
            label: 'Excluded',
        },
        {
            active: false,
            status: 'deleted',
            label: 'Deleted',
            super: true,
            secondary: true,
        },
    ];

    /**
     * Budget categories tabs
     */
    static BudgetCategoryTabs: CallbackTab[] = [
        {
            active: true,
            status: 'expenses',
            label: 'Expense categories',
        },
        {
            active: false,
            status: 'income',
            label: 'Income categories',
        },
        {
            active: false,
            status: 'assets',
            label: 'Assets',
        },
        {
            active: false,
            status: 'liabilities',
            label: 'Liabilities',
        },
        {
            active: false,
            status: 'equity',
            label: 'Equity',
        },
    ];

    static BudgetIntervalTabs: CallbackTab[] = [
        {
            active: true,
            status: 'summary',
            label: 'Summary',
        },
        {
            active: false,
            status: 'yearly',
            label: 'Yearly',
        },
        {
            active: false,
            status: 'monthly',
            label: 'Monthly',
        },
    ];

    static ViolationTemplateTabs: CallbackTab[] = [
        {
            active: true,
            status: 'main',
            label: 'Message Templates',
        },
        {
            active: false,
            status: 'header',
            label: 'Header Templates',
        },
        {
            active: false,
            status: 'footer',
            label: 'Footer Templates',
        },
    ];
}
