<ng-template #loadingAvatar>
    <span class="avatar-block-icon avatar-loading animated fadeIn"
          [ngClass]="classMapping">
    </span>
    <span class="avatar-block-content avatar-loading">
        <span class="avatar-block-text">
            <span></span>
            <span></span>
        </span>
    </span>
</ng-template>

<ng-container *ngIf="!isLoadingInitialData; else loadingAvatar">
    <span class="avatar-block-icon" [ngClass]="classMapping">
        <ng-container [ngSwitch]="iconType">
            <span *ngSwitchCase="iconTypes.IMG" [ngClass]="classMapping" class="avatar-block-image">
                <img *ngIf="iconImage" alt="{{ 'Avatar image' }}" [ngSrc]="iconImage" fill/>
            </span>
            <mat-icon *ngSwitchCase="iconTypes.SVG" [svgIcon]="svgIcon"></mat-icon>
            <mat-icon *ngSwitchCase="iconTypes.ICON">{{ icon }}</mat-icon>
            <span *ngSwitchCase="iconTypes.INITIALS" class="initials">{{ initials }}</span>
        </ng-container>
    </span>
    <span class="avatar-block-content">
        <span class="avatar-block-text">
            <ng-content select="[avatarTitle]"></ng-content>
            <ng-content select="[avatarDescription]"></ng-content>
            <ng-content></ng-content>
        </span>
    </span>
</ng-container>
