<nav class="navbar navbar-light pt-2">
    <div class="navbar-brand-container flex-row align-items-center">
        <!-- brand -->
        <a class="navbar-brand d-flex"
           matTooltip="{{ 'Personalize your account by uploading your logo on the Organization Settings page!' }}"
           [matTooltipDisabled]="isOrgLogoSet"
           (click)="routeFromLogo()">
            <img alt="{{ 'Home' }}"
                 class="logo img"
                 src="{{ orgLogo }}"
                 [class.payhoa-logo]="!isOrgLogoSet"/>
        </a>
    </div>

    <!-- left side of header -->
    <ul class="navbar-nav flex-row align-items-center mr-auto d-md-flex d-none">
        <ng-container *ngIf="jwt">
            <li *ngIf="!jwt.superUser" class="navbar-payment nav-item px-0 d-md-flex d-none mr-2">
                <!-- make a payment -->
                <a aria-label="Make a Payment" class="btn btn-primary btn-sm d-flex"
                   [routerLink]="['/app/make-payment']">
                    {{ 'Make a Payment' }}
                </a>
            </li>

            <li *ngIf="isDisplayingGlobalSearch && (jwt.superUser || jwt.admin)"
                class="navbar-dropdown-search nav-item px-0 d-flex mr-2">
                <lf-dropdown-search (dropdownSearchRedirect)="switchFromDropdownSearch($event)"></lf-dropdown-search>
            </li>

            <li *ngIf="jwt.superUser" class="navbar-admin nav-item px-0 d-flex">
                <ng-template #goToAdminButton>
                    <app-super-button (onClick)="goToAdmin(); $event.stopPropagation()">{{ 'Admin' }}</app-super-button>
                </ng-template>

                <ng-container *ngIf="layout === 'admin'; else goToAdminButton">
                    <app-super-button [routeTo]="['/app/organization/dashboard']">{{ 'Back to App' }}</app-super-button>
                </ng-container>
            </li>
        </ng-container>
    </ul>

    <!-- right side of header -->
    <ul class="navbar-nav flex-row align-items-center">
        <li *ngIf="jwt.superUser && jwt.orgId !== 3582" class="navbar-settings nav-item d-md-flex d-none">
            <button class="btn btn-outline-primary" (click)="switchOrganization(3582)">Switch To Demo</button>
        </li>
        <li *ngIf="hasOrgViewPermission && jwt.id !== 226155"
            class="navbar-settings nav-item px-0 d-md-flex d-none">
            <!-- organization settings cog -->
            <a class="btn btn-sm btn-icon d-flex align-content-center"
               matTooltip="{{ 'Organization Settings' }}"
               [routerLink]="['/app/settings/organization']">
                <mat-icon svgIcon="settings"></mat-icon>
            </a>
        </li>

        <ng-container *ngIf="isLoggedIn && !isShowingLoadingModal">
            <li class="navbar-user nav-item px-0 d-md-flex d-none">
                <!-- user menu -->
                <ng-template #profileSvg>
                    <mat-icon svgIcon="user"></mat-icon>
                </ng-template>

                <button class="btn btn-sm btn-icon d-flex align-content-center"
                        [class.impersonated]="jwt?.impersonatedBy"
                        matTooltip="{{ 'PayHOA Account' + (jwt?.impersonatedBy ? ' (Impersonated)' : '') }}"
                        [matMenuTriggerFor]="userMenu">
                    <ng-container *ngIf="profileImage; else profileSvg">
                        <img class="avatar rounded-circle mr-0"
                             src="{{ profileImage }}"/>
                    </ng-container>
                </button>
                <mat-menu #userMenu="matMenu"
                          xPosition="before"
                          class="mat-menu-header-user"
                          [overlapTrigger]="false">
                    <ng-container *ngIf="jwt">
                        <button class="mat-menu-welcome"
                                disabled
                                mat-menu-item>
                            {{ 'Welcome, ' + displayName }}
                        </button>

                        <button *ngIf="jwt.unitId" [routerLink]="['/app/members/' + jwt.memberId]" mat-menu-item>
                            {{ 'My Dashboard' }}
                        </button>
                        <button *ngIf="jwt.memberId" [routerLink]="['/app/settings/user']" mat-menu-item>
                            {{ 'Account Settings' }}
                        </button>
                        <button *ngIf="jwt.superUser" [routerLink]="['/pmc']" mat-menu-item>
                            {{ 'PMC Dashboard' }}
                        </button>
                        <button *ngIf="!jwt.superUser && hasPmcFeatureAccess && jwt.pmcId" [routerLink]="['/pmc/' + jwt.pmcId]" mat-menu-item>
                            {{ 'PMC Dashboard' }}
                        </button>
                        <button *ngIf="jwt.admin || jwt.superUser" [routerLink]="['/app/make-payment']" mat-menu-item>
                            {{ 'Make a Payment' }}
                        </button>
                        <button *ngIf="hasSupportPermission" (click)="sendIntercomMessage()" mat-menu-item>
                            {{ 'Submit an Issue' }}
                        </button>

                        <button *ngIf="jwt.impersonatedBy" (click)="stopImpersonation()" mat-menu-item>
                            {{ 'Stop Impersonation' }}
                            <app-super-star></app-super-star>
                        </button>
                        <button *ngIf="jwt.superUser" (click)="loadSiteMaster()" mat-menu-item>
                            {{ 'View Master' }}
                            <app-super-star></app-super-star>
                        </button>
                        <button *ngIf="jwt.superUser" (click)="openAuditModal()" mat-menu-item>
                            {{ 'Audits' }}
                            <app-super-star></app-super-star>
                        </button>
                        <a *ngIf="!environment.production" [href]="environment.MailHogUrl" mat-menu-item>
                            {{ 'MailHog' }}
                            <app-super-star></app-super-star>
                        </a>
                        <button *ngIf="environment.LegFiCoreApi.indexOf('staging') !== -1" (click)="seedEnvironment()" mat-menu-item>
                            {{ 'Run Rainforest Seeder' }}
                            <app-super-star></app-super-star>
                        </button>
                    </ng-container>
                    <button class="mat-menu-logout"
                            mat-menu-item
                            [attr.aria-label]="'Sign Out'"
                            (click)="logout()">
                        {{ 'Sign Out' }}
                    </button>
                </mat-menu>
            </li>

            <li *ngIf="jwt"
                class="navbar-switcher nav-item px-0 d-md-flex d-none">
                <!-- organization (or unit) switcher -->
                <app-header-switcher [switcherType]="jwt.unitId ? 'unit' : 'org'"
                                     (switchRequested)="switchFromRequest($event)"
                                     (newHoaRequested)="showNewHoaDialog()"></app-header-switcher>
            </li>
        </ng-container>

        <!-- toggler icon, only appears on mobile / when app sidenav goes away -->
        <img *ngIf="isInMobileMode"
             class="custom-toggler"
             src="/assets/images/app/icons/mobile-menu-toggle-btn.png"
             (click)="toggleSidenav.emit()"/>
    </ul>
</nav>

<div *ngIf="jwt && isInMobileMode"
     class="navbar-switcher d-flex flex-row justify-content-end align-items-center border-bottom px-3 py-2">
    <div *ngIf="jwt.superUser || jwt.impersonatedBy"
         class="ml-0 mr-auto">
        <ng-template #goToAdminButton>
            <app-super-button (onClick)="goToAdmin(); $event.stopPropagation()">{{ 'Admin' }}</app-super-button>
        </ng-template>

        <ng-container *ngIf="layout === 'admin'; else goToAdminButton">
            <app-super-button [routeTo]="['/app/organization/dashboard']">{{ 'Back to App' }}</app-super-button>
        </ng-container>
    </div>

    <!-- organization (or unit) switcher -->
    <app-header-switcher [switcherType]="jwt.unitId ? 'unit' : 'org'"
                         (switchRequested)="switchFromRequest($event)"
                         (newHoaRequested)="showNewHoaDialog()"></app-header-switcher>
</div>

<lf-modal *ngIf="isShowingLoadingModal"
          class="loading-modal"
          [closable]="false"
          [type]="loadingModalComponent"
          [(show)]="isShowingLoadingModal"></lf-modal>

