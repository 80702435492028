import { UnifiedBankAccount } from '../unified-bank-account';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import { COAAccount } from '../../../services/accounting/chart-of-accounts.service';
import Moment = moment.Moment;

export const ASSET_ACCOUNT_TYPE = 'asset';

export class FixedAsset implements COAAccount
{
    static readonly INTERNAL_USE_ACCOUNTS_RECEIVABLE = 1;
    static readonly INTERNAL_USE_FUNDS_IN_TRANSIT = 2;
    static readonly INTERNAL_USE_UNDEPOSITED_FUNDS = 3;

    id: number;
    organizationId: number;
    label: string;
    old?: any[];
    startingBalance: number;
    startingBalanceDate: Moment;
    balance: number;
    internalBalance: number;
    internalUse: number;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    retiredOn: Moment = null;
    beingEdited = false;
    presentationBalance: number;
    ownerType: string;
    ownerId: number;
    owner: UnifiedBankAccount;
    name: string;
    reportVisible: boolean;

    readonly accountType = ASSET_ACCOUNT_TYPE;

    constructor(request: any = {}) {
        this.id = request.id || null;
        this.organizationId = request.organizationId || null;
        this.label = request.label || '';
        this.name = request.label || '';
        this.startingBalance = request.startingBalance || null;
        this.presentationBalance = request.startingBalance ? request.startingBalance / 100 : null;
        this.balance = request.balance || 0;
        this.internalBalance = request.internalBalance || 0;
        this.internalUse = request.internalUse || 0;
        this.ownerId = request.ownerId || null;
        this.ownerType = request.ownerType || null;
        this.reportVisible = request.reportVisible || false;

        const timezone = LegFiJwtService.getTimezone();
        this.startingBalanceDate = request.startingBalanceDate ? moment
                .utc(request.startingBalanceDate, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.createdAt = (request.createdAt) ? moment
                .utc(request.createdAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.updatedAt = (request.updatedAt) ? moment
                .utc(request.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.deletedAt = (request.deletedAt) ? moment
                .utc(request.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;

        if (request.owner && request.ownerType === 'App\\Models\\Entities\\UnifiedBankAccount') {
            this.owner = new UnifiedBankAccount(request.owner);
        } else {
            this.owner = null;
        }
    }
}
