import { environment } from 'environments/environment';
import { FeatureFlagContext } from '../services/organization/feature-flags.service';

export class Routes
{
    public static CoreV2Module = {
        AvailableFeatureFlags: (context: FeatureFlagContext) => {
            return `/core/v2/feature-flags/${context}/available`;
        },
        FeatureFlags: (context: FeatureFlagContext, contextId: number) => {
            return `/core/v2/feature-flags/${context}/${contextId}`;
        },
    };

    public static LegFiCore = {
        Admin: '/admin',
        ArchiveRecords: function (organizationId: number) {
            return Routes.LegFiCore.Organization(organizationId) + '/archive';
        },
        Charges: '/charges',
        Charge: function (chargeId: number) {
            return Routes.LegFiCore.Charges + '/' + chargeId;
        },
        Join: '/join',
        JwtPing: '/jwt-ping',
        Login: '/login',
        MemberActivations: function (organizationId: number, memberIds: number[]) {
            return (
                    Routes.LegFiCore.Organization(organizationId) +
                    '/memberships/activate/resend?members=' +
                    memberIds.join(',')
            );
        },
        MemberActivation: function (memberId: number, nonce: string) {
            return '/memberships/' + memberId + '/activate/' + nonce;
        },
        KeepAlive: '/keep-alive',
        Notes: '/notes',
        Note: function (noteId: number) {
            return Routes.LegFiCore.Notes + '/' + noteId;
        },
        OrgNote: function (orgId: number, noteId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/notes/' + noteId;
        },
        GenericNote: function (orgId: number, modelId: number, modelType: string) {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/' + modelType + '/' + modelId + '/notes';
        },
        NonceLogin: '/nonce-login',
        PasswordResetRequest: '/password/email',
        PasswordReset: '/password/reset',
        PaymentItems: '/payment-items',
        IssueCreditForPaymentItem: function (paymentItemId: number) {
            return Routes.LegFiCore.PaymentItems + '/' + paymentItemId + '/credit';
        },
        PaymentGroups: '/payment-groups',
        PaymentGroup: function (paymentGroupId: number) {
            return Routes.LegFiCore.PaymentGroups + '/' + paymentGroupId;
        },
        PaymentLogin: '/payment-login',
        Plaid: '/plaid',
        PlaidSearch: function (context: 'auth' | 'transactions') {
            return '/plaid/search/' + context;
        },
        PlaidLinkToken: function () {
            return '/plaid/link';
        },
        PlaidTokens: '/plaid-tokens',
        Products: '/admin/products',
        Product: function (productId: number) {
            return Routes.LegFiCore.Products + '/' + productId;
        },
        PropertyManagementCompanies: '/pmc',
        PropertyManagementCompany: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}`;
        },
        PropertyManagementOrganizations: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/organizations`;
        },
        PropertyManagementRequestForms: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/forms`;
        },
        PropertyManagementRequestFormCounts: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/request-counts`;
        },
        PropertyManagementRequestFormSubmissions: function (pmcId: number) {
            return this.PropertyManagementRequestForms(pmcId) + `/submissions`;
        },
        PropertyManagementRequestFormSubmissionStatuses: function (pmcId: number) {
            return this.PropertyManagementRequestForms(pmcId) + `/submission-statuses`;
        },
        PropertyManagementViolationCounts: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/violation-counts`;
        },
        PropertyManagementViolations: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/violations`;
        },
        PropertyManagementViolationStatuses: function (pmcId: number) {
            return this.PropertyManagementCompanies + `/${pmcId}/violation-statuses`;
        },

        AdminLockbox: function () {
            return '/admin/lockbox';
        },
        AdminLockboxDepositNotices: function () {
            return this.AdminLockbox() + '/deposit-notices';
        },
        AdminLockboxRejectDetails: function (depositNoticeId: number) {
            return '/admin/lockbox/reject-details' + ((depositNoticeId === null) ? '' : '/' + depositNoticeId);
        },
        AdminLockboxPostingDetails: function (depositNoticeId: number) {
            return '/admin/lockbox/posting-details/' + depositNoticeId;
        },
        AdminLockboxPostingDetail: function (postingDetailId: number) {
            return '/admin/lockbox/posting-detail/' + postingDetailId;
        },
        AdminLockboxDepositDetails: function (depositNoticeId: number) {
            return '/admin/lockbox/deposit-details/' + depositNoticeId;
        },
        AdminDeleteDepositNotice: function (depositNoticeId: number) {
            return this.AdminLockboxDepositNotices() + '/' + depositNoticeId;
        },
        Register: '/register',
        RegisterExisting: '/register/existing',
        Renewal: '/renew',
        SendVerificationPin: '/register/send-verification',
        VerifyPin: '/register/verify',
        Sites: '/sites',
        Site: function (siteId: number): string {
            return Routes.LegFiCore.Sites + '/' + siteId;
        },
        Stats: '/stats',
        OrganizationsPendingReview: '/admin/organizations/pending',
        SubscriptionsBreakdown: '/subscriptions/breakdown',
        SubscriptionsSummary: '/subscriptions/summary',
        SubscribedOrganizations: '/subscriptions/organizations',
        SubscriptionTemplates: '/subscription-templates',
        SubscriptionTemplate: function (subscriptionTemplateId: number) {
            return Routes.LegFiCore.SubscriptionTemplates + '/' + subscriptionTemplateId;
        },
        UpgradeToken: '/upgrade-token',

        ImpersonateUser: function (): string {
            return Routes.LegFiCore.Admin + '/impersonate';
        },
        RestoreSuperAdmin: function (): string {
            return Routes.LegFiCore.Admin + '/restore';
        },
        AdminFailedJobs: function (): string {
            return Routes.LegFiCore.Admin + '/failed-jobs';
        },
        AdminMasterOrg: function (): string {
            return Routes.LegFiCore.Admin + '/organizations/master';
        },
        AdminCreditOrg: function (): string {
            return Routes.LegFiCore.Admin + '/organizations/credit';
        },
        AdminProcessOrgPayment: function (): string {
            return Routes.LegFiCore.Admin + '/organizations/process';
        },
        AdminAssignedSalesRep: function (): string {
            return Routes.LegFiCore.Admin + '/assigned-sales-reps';
        },
        AdminAssignedSalesPatch: function (assignedSalesRepId: number): string {
            return Routes.LegFiCore.AdminAssignedSalesRep() + '/' + assignedSalesRepId;
        },
        AuxiliaryEmails: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/emails';
        },
        AuxiliaryContacts: '/contacts',
        AuxiliaryContactsPatch: function (contactId: number): string {
            return Routes.LegFiCore.AuxiliaryContacts + '/' + contactId;
        },
        BillPayVendors: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/vendors';
        },
        BillPaymentHistory: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/bill-payments';
        },
        BillPaymentHistoryToVendor: function (orgId: number, vendorId: number): string {
            return Routes.LegFiCore.BillPaymentHistory(orgId) + '/' + vendorId;
        },
        BillPayVendor: function (orgId: number, vendorId: number): string {
            return Routes.LegFiCore.BillPayVendors(orgId) + '/' + vendorId;
        },
        BillPayVendorAttachments: function (orgId: number, vendorId: number): string {
            return Routes.LegFiCore.BillPayVendor(orgId, vendorId) + '/attachments';
        },
        BillPayVendorAttachmentLink: function (orgId: number, vendorId: number, attachmentId: number): string {
            return Routes.LegFiCore.BillPayVendor(orgId, vendorId) + '/attachment/' + attachmentId + '/link';
        },
        BillPayVendorDocument: function (orgId: number, vendorId: number, attachmentId: number): string {
            return Routes.LegFiCore.BillPayVendorAttachments(orgId, vendorId) + '/' + attachmentId;
        },
        BillPayBankAccounts: function (): string {
            return '/bill-pay-accounts';
        },
        BillPayBankAccount: function (accountId: number): string {
            return this.BillPayBankAccounts() + '/' + accountId;
        },
        BillPayPayment: function (orgId: number): string {
            return this.BillPayBankAccounts() + '/' + orgId + '/bill-payments';
        },
        BillPayCancel: function (orgId: number, paymentId: number): string {
            return this.BillPayBankAccounts() + '/' + orgId + '/bill-payments/' + paymentId + '/cancel';
        },
        CustomFieldForOrg: function (orgId: number, type: string, fieldId: number): string {
            return Routes.LegFiCore.Organization(orgId) + `/manage-custom-fields/${type}/${fieldId}`;
        },
        CustomFieldsForOrg: function (orgId: number, type: string): string {
            return Routes.LegFiCore.Organization(orgId) + `/manage-custom-fields/${type}`;
        },
        CustomFieldValuesForMember: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/custom-fields';
        },
        CustomFieldValuesForVendor: function (orgId: number, vendorId: number): string {
            return Routes.LegFiCore.BillPayVendor(orgId, vendorId) + '/custom-fields';
        },
        DeleteOrganizationLogo: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/logo';
        },
        MembershipDirectory: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/member-directory';
        },
        Memberships: '/memberships',
        AddMemberToUnits: function (memberId: number) {
            return Routes.LegFiCore.Memberships + '/' + memberId + '/units';
        },
        EnableAutopay: function (memberId: number) {
            return this.Memberships + '/' + memberId + '/autopay/enable';
        },
        DisableAutopay: function (memberId: number) {
            return this.Memberships + '/' + memberId + '/autopay/disable';
        },
        Membership: function (orgId: number, memberId: number): string {
            return '/organizations/' + orgId + '/users/' + memberId;
        },
        ResetMemberPassword: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/reset-password';
        },
        UnconfirmedOwners(orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/unconfirmed-owners';
        },
        UpdateUnconfirmedOwner(orgId: number, ownerId: number): string {
            return Routes.LegFiCore.UnconfirmedOwners(orgId) + '/' + ownerId;
        },
        PeopleList: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/people-list';
        },
        MembershipBulkAdmin: function (orgId: number): string {
            return '/organizations/' + orgId + '/users/admin';
        },
        Members: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/users';
        },
        LockMembers: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/users/lock';
        },
        UnlockMembers: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/users/unlock';
        },
        Team: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/team';
        },
        TeamMember: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Team(orgId) + '/' + memberId;
        },
        Lob: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lob';
        },
        LobOrgBillPayMail: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lob/bill-pay';
        },
        LobOrgInvoiceMail: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lob/invoice';
        },
        LobOrgPDFMail: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lob/pdf';
        },
        LobOrgViolationMail: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lob/violation';
        },
        LobUserMailPref: function (orgId: number, userId: number): string {
            return Routes.LegFiCore.Organization(orgId) + `/lob/user/${userId}/mail-pref`;
        },
        MembersRestore: function (orgId: number): string {
            return Routes.LegFiCore.Members(orgId) + '/restore';
        },
        MembersCommsLog: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/communications/log';
        },
        MemberPaymentGroups: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/payment-groups';
        },
        MemberSummary: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/summary';
        },
        Organizations: '/organizations',
        Organization: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId;
        },
        OrganizationLockbox: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/lockbox';
        },
        RestoreOrganization: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/restore';
        },
        OrganizationDashboardWidgets: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.OrgDashboard(orgId) + '/widgets/' + memberId;
        },
        OrganizationPreferences: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/preferences';
        },
        VerifyOrganization: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/verify';
        },
        OrganizationCheckout: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/checkout';
        },
        OrganizationRates: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/rates';
        },
        OrganizationExpirationCheckout: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/correct';
        },
        SearchOrgList: function (): string {
            return Routes.LegFiCore.Organizations + '/search';
        },
        SendPaymentLoginEmail: function (): string {
            return Routes.LegFiCore.Memberships + '/payment-login';
        },
        SimpleOrgList: function (): string {
            return Routes.LegFiCore.Organizations + '/simple';
        },
        OrgDefaultPaymentMethod: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/default-payment-method';
        },
        OrgDepositAccounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/bank-accounts/deposit';
        },
        OrgDepositAccount: function (orgId: number, depositAccountId: number): string {
            return Routes.LegFiCore.OrgDepositAccounts(orgId) + '/' + depositAccountId;
        },
        RefreshOrgDepositAccount: function (orgId: number, depositAccountId: number): string {
            return Routes.LegFiCore.OrgDepositAccount(orgId, depositAccountId) + '/refresh';
        },
        OrgDepositAccountPendingPayments: function (orgId: number, depositAccountId: number) {
            return this.OrgDepositAccount(orgId, depositAccountId) + '/pending';
        },
        OrgPaymentMethods: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/payment-methods';
        },
        OrgBankAccounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/bank-accounts';
        },
        OrgBankAccount: function (orgId: number, bankAccountId: number): string {
            return Routes.LegFiCore.OrgBankAccounts(orgId) + '/' + bankAccountId;
        },
        PlaidBalanceHistory: function (orgId: number, bankAccountId: number): string {
            return Routes.LegFiCore.OrgBankAccounts(orgId) + '/' + bankAccountId + '/plaid-balance-history';
        },
        CreateOrgBankAccountAsset: function (orgId: number, bankAccountId: number): string {
            return Routes.LegFiCore.OrgBankAccounts(orgId) + '/' + bankAccountId + '/asset';
        },
        LinkedAccountPlaidFeed: function (orgId: number, linkedAccountId: number): string {
            return Routes.LegFiCore.OrgBankAccounts(orgId) + '/' + linkedAccountId + '/plaid-feed';
        },
        LinkedAccountPlaidImport: function (orgId: number, linkedAccountId: number): string {
            return Routes.LegFiCore.OrgBankAccounts(orgId) + '/' + linkedAccountId + '/plaid-import';
        },
        OrgCards: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/credit-cards';
        },
        OrgCard: function (orgId: number, cardId: number) {
            return Routes.LegFiCore.OrgCards(orgId) + '/' + cardId;
        },
        OrgCommunications: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications';
        },
        LastOnDemandSendPerUnit(orgId: number): string {
            return Routes.LegFiCore.OrgCommunications(orgId) + '/last-on-demand-send';
        },
        OrgCommunicationsView: function (orgId: number, activityId: number): string {
            return Routes.LegFiCore.OrgCommunications(orgId) + '/' + activityId + '/view';
        },
        OrgCommsSendSampleEmail: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/email/send-sample/' + memberId;
        },
        OrgCommsSampleEmail: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/email/sample/' + memberId;
        },
        OrgCommsEmail: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/email/members';
        },
        OrgCommsCalls: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/calls';
        },
        OrgCommsTexts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/sms';
        },
        OrgHistoriesPdfDownload: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/history-pdfs';
        },
        OrgStatementsPdfDownload: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/statement-pdfs';
        },
        OrgViolationsPdfDownload: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/violation-pdfs';
        },
        OrgStatementPdfPreviewForUnit: function (orgId: number, unitId: number, includeFuture: boolean): string {
            return Routes.LegFiCore.Organization(orgId) + '/statement-pdf/' + unitId + '/' + (includeFuture ? 1 : 0);
        },
        OrgCommsLog: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/log';
        },
        OrgCommsLogFilters: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/log/filters';
        },
        OrgEmailStatusGraph: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/email-status-graph';
        },
        OrgEmailStatusCounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/email-status-counts';
        },
        OrgEmailCategoryCounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/email-category-counts';
        },
        OrgCommCountsSmsCalls: function (orgId: number, which: string): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/donut-counts/' + which;
        },
        OrgPaperMailGraphCounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/paper-mail-graph-counts';
        },
        OrgPaperMailStatusCounts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/communications/paper-mail-status-counts';
        },
        OrgSurveys: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/surveys';
        },
        OrgSurvey: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurveys(orgId) + '/' + surveyId;
        },
        OrgCopySurvey: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurvey(orgId, surveyId) + '/copy';
        },
        OrgSurveyResults: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurveys(orgId) + '/' + surveyId + '/results';
        },
        OrgSurveyResponse: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurveys(orgId) + '/' + surveyId + '/response';
        },
        OrgSurveyShare: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurvey(orgId, surveyId) + '/share';
        },
        CloseOrgSurvey: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurvey(orgId, surveyId) + '/close';
        },
        ReopenOrgSurvey: function (orgId: number, surveyId: number): string {
            return Routes.LegFiCore.OrgSurvey(orgId, surveyId) + '/open';
        },
        CopyNewResourceDocuments: function (orgId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/copy-new-resources';
        },
        BulkOrgDocuments: function (orgId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/bulk-download';
        },
        OrgDocuments: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/documents';
        },
        OrgDocument: function (orgId: number, documentId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/' + documentId;
        },
        OrgDocumentDetails: function (orgId: number, documentId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/' + documentId + '/details';
        },
        OrgUnitDocumentDetails: function (orgId: number, documentId: number, unitId: number): string {
            return Routes.LegFiCore.OrgDocumentDetails(orgId, documentId) + '/' + unitId;
        },
        DownloadOrgDocument: function (orgId: number, documentId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/' + documentId + '/download';
        },
        OrgSubscriptions: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/subscriptions';
        },
        OrgSubscription: function (orgId: number, subscriptionId: number): string {
            return Routes.LegFiCore.OrgSubscriptions(orgId) + '/' + subscriptionId;
        },
        DocumentPublicize: function (orgId: number, documentId: number): string {
            return Routes.LegFiCore.OrgDocument(orgId, documentId) + '/public';
        },
        DocumentUpdate: function (orgId: number, documentId: number): string {
            return Routes.LegFiCore.OrgDocument(orgId, documentId) + '/update';
        },
        DocumentAttachEmail: function (orgId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/attach';
        },
        DocumentDeleteEmailAttach: function (orgId: number, fileId: number): string {
            return Routes.LegFiCore.OrgDocuments(orgId) + '/attach/' + fileId;
        },
        UnitDocuments: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/units/' + unitId + '/documents';
        },
        OrgDashboard: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/dashboard';
        },
        Collections: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/collections';
        },
        CollectionsPastDueEmails: function (orgId: number): string {
            return Routes.LegFiCore.Collections(orgId) + '/past-due-emails';
        },
        CollectionProgress: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/graph/collection-progress';
        },
        PaymentsGraph: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/graph/payments';
        },
        OrgModules: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/modules';
        },
        Budgets: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/budgets';
        },
        BudgetCopy: function (orgId: number): string {
            return Routes.LegFiCore.Budgets(orgId) + '/copy';
        },
        BudgetDashboard: function (orgId: number): string {
            return Routes.LegFiCore.Budgets(orgId) + '/dashboard';
        },
        BudgetDetail: function (orgId: number): string {
            return Routes.LegFiCore.Budgets(orgId) + '/details';
        },
        BudgetItem: function (orgId: number, budgetItemId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/budgetItem/' + budgetItemId;
        },
        BudgetDistribute: function (orgId: number): string {
            return Routes.LegFiCore.Budgets(orgId) + '/distribute';
        },
        Category: function (orgId: number, categoryId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/categories/' + categoryId;
        },
        CategoryItemCount: function (orgId: number, categoryId: number): string {
            return Routes.LegFiCore.Category(orgId, categoryId) + '/count';
        },
        CategorySort: function (orgId: number, categoryId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/categories-sort/' + categoryId;
        },
        Categories: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/categories';
        },
        Owners: '/owners',
        Owner: function (ownerId: number): string {
            return Routes.LegFiCore.Owners + '/' + ownerId;
        },
        Occupancy: function (id: number): string {
            return '/occupancies/' + id;
        },
        UnitOwners: function (unitId: number): string {
            return Routes.LegFiCore.Units + '/' + unitId + '/owners';
        },
        UnitPreviousOwners: function (unitId: number): string {
            return Routes.LegFiCore.UnitOwners(unitId) + '/previous';
        },
        AdminPaymentGroups: function (): string {
            return Routes.LegFiCore.Admin + '/payment-groups';
        },
        AdminPaymentFilters: function (): string {
            return this.AdminPaymentGroups() + '/filters';
        },
        IncomingPaymentGroups: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups';
        },
        IncomingPaymentsFilters: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/filters';
        },
        IncomingPaymentsReceipts: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/receipts';
        },
        IncomingPaymentGroup: function (orgId: number, paymentId: number): string {
            return Routes.LegFiCore.IncomingPaymentGroups(orgId) + '/' + paymentId;
        },
        UpdatePaymentGroupTotals: function (organizationId: number, paymentGroupId: number) {
            return Routes.LegFiCore.IncomingPaymentGroup(organizationId, paymentGroupId) + '/update-totals';
        },
        IssuedCharges: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/charges/issued';
        },
        IssuedChargesByTitle: function (orgId: number): string {
            return Routes.LegFiCore.IssuedCharges(orgId) + '/title';
        },
        IssuedChargesCopyList: function (orgId: number): string {
            return Routes.LegFiCore.IssuedCharges(orgId) + '/copy';
        },
        IssuedChargesFilters: function (orgId: number): string {
            return Routes.LegFiCore.IssuedCharges(orgId) + '/filters';
        },
        IssuedChargesOverview: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/issuedChargesOverview';
        },
        IssuedChargesForgive: function (): string {
            return Routes.LegFiCore.Charges + '/forgive';
        },
        IssuedChargesPaymentPlan: function (): string {
            return Routes.LegFiCore.Charges + '/payment-plan';
        },
        IssuedChargesPreview: function (): string {
            return Routes.LegFiCore.Charges + '/preview';
        },
        IssuedChargesReinstate: function (): string {
            return Routes.LegFiCore.Charges + '/reinstate';
        },
        IssuedChargesSendInvoice: function (): string {
            return Routes.LegFiCore.Charges + '/send-invoices';
        },
        RecurringChargeTemplates: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/charges/recurring-templates';
        },
        ExpectedCharges: function (orgId: number, templateId: number): string {
            return Routes.LegFiCore.RecurringChargeTemplates(orgId) + '/' + templateId + '/expected-charges';
        },
        RecurringChargeTemplatesForUnit: function (orgId: number, unitId: number) {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/charges/recurring-templates';
        },
        PauseRecurringChargeTemplatesForUnit: function (orgId: number, unitId: number) {
            return Routes.LegFiCore.RecurringChargeTemplatesForUnit(orgId, unitId) + '/pause';
        },
        UnpauseRecurringChargeTemplatesForUnit: function (orgId: number, unitId: number) {
            return Routes.LegFiCore.RecurringChargeTemplatesForUnit(orgId, unitId) + '/unpause';
        },
        RecurringChargeTemplate: function (orgId: number, templateId: number): string {
            return Routes.LegFiCore.RecurringChargeTemplates(orgId) + '/' + templateId;
        },
        RecurringChargeCharges: function (orgId: number, templateId: number): string {
            return Routes.LegFiCore.RecurringChargeTemplate(orgId, templateId) + '/charges';
        },
        RecurringChargeTemplatesPause: function (orgId: number): string {
            return Routes.LegFiCore.RecurringChargeTemplates(orgId) + '/pause';
        },
        RecurringChargeTemplatesUnpause: function (orgId: number): string {
            return Routes.LegFiCore.RecurringChargeTemplates(orgId) + '/unpause';
        },
        LockboxPaymentGroups: function (orgId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/lockbox';
        },
        OfflinePaymentGroups: function (orgId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/offline';
        },
        LinkPaymentGroups: function (orgId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/link';
        },
        UnlinkPaymentGroups: function (orgId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/payment-groups/unlink';
        },
        ReceivedCharges: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/charges/received';
        },
        ARActivity: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/charges/activity';
        },
        MasterReports: function () {
            return '/reports';
        },
        Reports: function (orgId: number) {
            return Routes.LegFiCore.Organization(orgId) + '/reports';
        },
        ReportsHomepage: function () {
            return '/reports/home';
        },

        ReportPackets: function () {
            return '/report-packets';
        },

        ReportPacket: function (reportPacketId: number) {
            return this.ReportPackets() + '/' + reportPacketId;
        },

        SavedReports: function () {
            return '/saved-reports';
        },

        SavedReport: function (savedReportId: number) {
            return this.SavedReports() + '/' + savedReportId;
        },

        SavedReportDownload: function (savedReportId: number) {
            return this.SavedReport(savedReportId) + '/download';
        },

        SavedReportShare: function (savedReportId: number) {
            return this.SavedReport(savedReportId) + '/share';
        },

        SavedReportEmail: function (savedReportId: number) {
            return this.SavedReport(savedReportId) + '/email';
        },

        ReportsGeneralLedger: function (orgId: number, format: string) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/general-ledger/'
                    + format;
        },
        ReportsGenerateGeneralLedger: function (orgId: number) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/general-ledger/generate';
        },

        ReportsAccountRegister: function (orgId: number, format: string) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/account-register/'
                    + format;
        },
        ReportsAccountRegisterFilters: function (orgId: number) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/account-register/filters';
        },

        ReportsBalanceSheetDetails: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/balance-sheet/'
                    + (pdf ? 1 : 0);
        },
        ReportsProfitVsLoss: function (orgId: number, report: string, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/profit-vs-loss/' + report + '/'
                    + (pdf ? 1 : 0);
        },
        ReportsProfitVsLossSummary: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsProfitVsLoss(orgId, 'summary', pdf);
        },
        ReportsProfitVsLossByMonth: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsProfitVsLoss(orgId, 'by-month', pdf);
        },
        ReportsProfitVsLossDetail: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsProfitVsLoss(orgId, 'details', pdf);
        },
        ReportsProfitVsLossPercentage: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsProfitVsLoss(orgId, 'percentage', pdf);
        },

        ReportsJournalEntries: function (orgId: number, format: string = 'json') {
            return Routes.LegFiCore.Reports(orgId)
                    + '/journal-entries/'
                    + format;
        },
        ReportReconciliations: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/reconciliations/'
                    + (pdf ? 1 : 0);
        },

        ReportsOrganizations: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/organizations/'
                    + format;
        },
        ReportsMasterBilling: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/master-billing/'
                    + format;
        },
        ReportsApiPerformance: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/api-performance/'
                    + format;
        },
        ReportsNonbilled: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/non-billed/'
                    + format;
        },
        ReportsBookkeepingProspects: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/bookkeeping-prospects/'
                    + format;
        },
        ReportsChurn: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/churn/'
                    + format;
        },
        ReportsNewCustomers: function (format: string) {
            return Routes.LegFiCore.MasterReports()
                    + '/new-customers/'
                    + format;
        },

        ReportsBudgetVsActual: function (orgId: number, report: string, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId)
                    + '/budget-vs-actual/' + report + '/'
                    + (pdf ? 1 : 0);
        },

        ReportsBudgetSummary: function (orgId: number, mode: 'summary' | 'yearly' | 'monthly', pdf: boolean) {
            return `${Routes.LegFiCore.Reports(orgId)}/budget-summary/${mode}/${(pdf ? 1 : 0)}`;
        },

        ReportsBudgetVsActualSummary: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsBudgetVsActual(orgId, 'summary', pdf);
        },

        ReportsBudgetVsActualByMonth: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.ReportsBudgetVsActual(orgId, 'by-month', pdf);
        },

        ReportUnitBalanceList(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/unit-balance/list/' + (pdf ? 1 : 0);
        },
        ReportUnitBalanceSummary(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/unit-balance/summary/' + (pdf ? 1 : 0);
        },
        ReportUnitBalanceDetail(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/unit-balance/detail/' + (pdf ? 1 : 0);
        },
        ReportDelinquentAccountsSummary(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/delinquent-accounts/summary/' + (pdf ? 1 : 0);
        },
        ReportDelinquentAccountsDetail(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/delinquent-accounts/detail/' + (pdf ? 1 : 0);
        },
        ReportInvoicesByUnit(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/invoices/by-unit/' + (pdf ? 1 : 0);
        },
        ReportInvoicesByRevenueCategory(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/invoices/by-revenue-category/' + (pdf ? 1 : 0);
        },
        ReportInvoicesByTitle(orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/invoices/by-title/' + (pdf ? 1 : 0);
        },
        ReportsReceiptsDetail: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/payments/receipts/' + (pdf ? 1 : 0);
        },
        ReportsPaymentsByUnit: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/payments/by-unit/' + (pdf ? 1 : 0);
        },
        ReportsPrepaymentsByUnit: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/prepayments/by-unit/' + (pdf ? 1 : 0);
        },
        ReportsNewRecurringCharges: function (orgId: number, format: string) {
            return Routes.LegFiCore.Reports(orgId) + '/recurring/new/' + format;
        },
        ReportReturnedPayments: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/returned-payments/' + (pdf ? 1 : 0);
        },
        ReportDepositDetails: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/deposit-details/' + (pdf ? 1 : 0);
        },
        ReportViolations: function (orgId: number, format: string) {
            return Routes.LegFiCore.Reports(orgId) + '/violations/' + format;
        },
        ReportsCheckRegister: function (orgId: number, format: string) {
            return Routes.LegFiCore.Reports(orgId) + '/check-register/' + format;
        },
        ReportsExpensesByVendor: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/by-vendor/' + (pdf ? 1 : 0);
        },
        ReportsVendorOverview: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/vendor-overview/' + (pdf ? 1 : 0);
        },
        ReportsVendorOverviewFields: function (orgId: number) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/vendor-overview/fields';
        },
        ReportsSubscriptionHistory: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/subscription-history/' + (pdf ? 1 : 0);
        },
        ReportsExpensesByCategory: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/by-category/' + (pdf ? 1 : 0);
        },
        ReportsExpensesDetail: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/expenses/detail/' + (pdf ? 1 : 0);
        },
        ReportsAgingOfAccounts: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/aging-of-accounts/' + (pdf ? 1 : 0);
        },
        ReportsCustomFields: function (orgId: number) {
            return Routes.LegFiCore.Reports(orgId) + '/custom/fields';
        },
        ReportsCustom: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/custom/' + (pdf ? 1 : 0);
        },
        ReportsContactInfo: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/unit-contact-info/' + (pdf ? 1 : 0);
        },
        ReportsVendorInfo: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/vendor-info/' + (pdf ? 1 : 0);
        },
        ReportsOwnershipChange: function (orgId: number, pdf: boolean) {
            return Routes.LegFiCore.Reports(orgId) + '/ownership-change/' + (pdf ? 1 : 0);
        },
        BankReconciliation: function (orgId: number, reconciliationId?: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/reconciliation' + (reconciliationId
                    ? `/${reconciliationId}`
                    : '');
        },
        BankReconciliationByAccount: function (orgId: number, accountId?: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/reconciliation/account/' + accountId;
        },
        BankReconciliationTransaction: function (
                orgId: number,
                reconciliationId: number,
                transactionId: number,
        ): string {
            return Routes.LegFiCore.BankReconciliation(orgId, reconciliationId) + '/transaction/' + transactionId;
        },
        Transaction: function (orgId: number, transactionId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/transactions/' + transactionId;
        },
        Transactions: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/transactions';
        },
        AssociatePendingFunds: function (orgId: number, transactionId: number, paymentAttemptId: number) {
            return Routes.LegFiCore.Transaction(orgId, transactionId) + '/associate-pending/' + paymentAttemptId;
        },
        DeleteTransactions: function (orgId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/delete';
        },
        RestoreTransactions: function (orgId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/restore';
        },
        TransactionFilters: function (orgId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/transactionFilters';
        },
        TransactionsCategory: function (orgId: number, catId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/category/' + catId;
        },
        TransactionCategoryTotals: function (orgId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/categoryTotals';
        },
        TransactionAttachments: function (orgId: number, transactionId: number): string {
            return Routes.LegFiCore.Transaction(orgId, transactionId) + '/attachments';
        },
        TransactionsMerge: function (orgId: number): string {
            return Routes.LegFiCore.Transactions(orgId) + '/merge';
        },
        TransactionsBulkImport: function (orgId: number): string {
            return '/organizations/' + orgId + '/transactions/bulk-import';
        },
        TransactionsBulkImportSupportedColumns: function (orgId: number): string {
            return Routes.LegFiCore.TransactionsBulkImport(orgId) + '/columns';
        },
        TransactionsBulkImportFileCheck: function (orgId: number): string {
            return Routes.LegFiCore.TransactionsBulkImport(orgId) + '/file-check';
        },
        User: function (userId: number): string {
            return '/users/' + userId;
        },
        SwitchContext: function (orgId: number): string {
            return '/users/switch-account/' + orgId;
        },
        PaymentBankAccounts: '/payment-bank-accounts',
        PaymentBankAccount: function (accountId: number) {
            return this.PaymentBankAccounts + '/' + accountId;
        },
        VerifyPaymentBankAccount: function (accountId: number) {
            return this.PaymentBankAccount(accountId) + '/verify';
        },
        UserPaymentMethods: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/payment-methods';
        },
        UserPaymentBankAccounts: function (userId: number) {
            return this.User(userId) + '/payment-bank-accounts';
        },
        UserPaymentBankAccount: function (userId: number, accountId: number) {
            return this.UserPaymentBankAccounts(userId) + '/' + accountId;
        },
        OrgPaymentBankAccounts: function (orgId: number) {
            return this.Organization(orgId) + '/payment-bank-accounts';
        },
        OrgPaymentBankAccount: function (orgId: number, accountId: number) {
            return this.OrgPaymentBankAccounts(orgId) + '/' + accountId;
        },

        UserSmsAuthSetup: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/sms/setup';
        },
        UserSmsAuthConfirm: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/sms/confirm';
        },
        UserSmsAuthSend: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/sms/send';
        },
        UserSmsAuthDisable: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/sms/disable';
        },
        UserSmsAuthValidate: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/sms/validate';
        },
        UserGoogleAuthDisable: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/google/disable';
        },
        UserGoogleAuthSetup: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/google/setup';
        },
        UserGoogleAuthValidate: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/2fa/google/validate';
        },
        UserCards: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/credit-cards';
        },
        UserCard: function (userId: number, cardId: number): string {
            return Routes.LegFiCore.UserCards(userId) + '/' + cardId;
        },
        UserCheckout: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/checkout';
        },
        UserCharges: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/charges';
        },
        UserChangePassword: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/change-password';
        },
        UserNotificationPreferences: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/notification-preferences';
        },
        UserNotificationPreference: function (userId: number, preferenceId: number): string {
            return Routes.LegFiCore.User(userId) + '/notification-preferences/' + preferenceId.toString();
        },
        UserMemberships: function (userId: number): string {
            return Routes.LegFiCore.User(userId) + '/memberships';
        },
        // Calendar Events
        Calendar: '/calendar',
        CalendarEvents: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/calendar/events';
        },
        CalendarEvent: function (organizationId: number, eventId: number): string {
            return Routes.LegFiCore.CalendarEvents(organizationId) + '/' + eventId;
        },
        CalendarNotifications: function (organizationId: number): string {
            return Routes.LegFiCore.CalendarEvents(organizationId) + '/notify';
        },
        // OAuth Routes
        GoogleOAuth: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/oauth/google/';
        },
        OrganizationOAuthSettings: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/oauth/settings/';
        },
        GoogleOAuthSignout: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/oauth/google/signout';
        },
        OAuthState: function (): string {
            return `/oauth/token/state/`;
        },
        GoogleCalendar: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/oauth/google/calendar/';
        },
        GoogleCalendarList: function (organizationId: number): string {
            return Routes.LegFiCore.Organizations + '/' + organizationId + '/oauth/google/calendar/list';
        },
        LinkedFiles(subject: string, id: number): string {
            return `/files/${subject}/${id}`;
        },
        UploadFile() {
            return `/file/upload`;
        },
        GetFileUrls(id: number) {
            return `/file/url/${id}`;
        },
        CreateFileAssociation() {
            return `/file/link`;
        },
        SeverFileLink(id: number, linked_with_subject: string, linked_with_id: number): string {
            return `/file/${id}/${linked_with_subject}/${linked_with_id}`;
        },
        SeverFileLinks(ids: number[], linked_with_subject: string, linked_with_id: number): string {
            return `/file/${linked_with_subject}/${linked_with_id}?ids=` + ids.join(',');
        },

        Violations: '/violations',
        Violation: function (id: number): string {
            return Routes.LegFiCore.Violations + '/' + id;
        },
        ViolationStatus: function (id: number): string {
            return Routes.LegFiCore.Organization(id) + '/violation-status';
        },
        ViolationTemplates: function (id: number): string {
            return Routes.LegFiCore.Organization(id) + '/violation-templates';
        },
        ViolationHeaderFooter: function (id: number): string {
            return Routes.LegFiCore.Organization(id) + '/violation-header-footer';
        },
        OrganizationViolations: function (orgId: number, isExport: boolean = false): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/violations' + (isExport ? '?export=1' : '');
        },
        OrganizationViolationAccessors: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/violations/accessors';
        },
        OrganizationViolationGraph: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/violations/graphs';
        },
        OrgViolationPastDueCounts: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/violations/past-due';
        },
        UnitViolations: function (orgId: number): string {
            return '/organizations/' + orgId + '/units/violations';
        },
        ViolationEmails: function (id: number): string {
            return Routes.LegFiCore.Organization(id) + '/violations/email';
        },
        ViolationResolution: function (id: number): string {
            return Routes.LegFiCore.Organization(id) + '/violations/resolution';
        },
        UnitViolationFines: function (orgId: number): string {
            return Routes.LegFiCore.Organizations + '/' + orgId + '/violations/fines';
        },
        ViolationComments: function (id: number): string {
            return Routes.LegFiCore.Violation(id) + '/comments';
        },

        /** Message board routes */
        MessageBoardBase: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/message-board';
        },
        MessageBoards: function (orgId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + '/boards';
        },
        MessageBoard: function (orgId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/board`;
        },
        MessageBoardDetail: function (orgId: number, boardId: number): string {
            return Routes.LegFiCore.MessageBoard(orgId) + `/${boardId}`;
        },
        MessageBoardDetailAction: function (orgId: number, boardId: number, action: string): string {
            return Routes.LegFiCore.MessageBoardDetail(orgId, boardId) + `/${action}`;
        },
        MessageBoardThread: function (orgId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/thread`;
        },
        MessageBoardThreadDetail: function (orgId: number, threadId: number): string {
            return Routes.LegFiCore.MessageBoardThread(orgId) + `/${threadId}`;
        },
        MessageBoardThreadDetailAction: function (orgId: number, threadId: number, action: string): string {
            return Routes.LegFiCore.MessageBoardThreadDetail(orgId, threadId) + `/${action}`;
        },
        MessageBoardComment: function (orgId: number, commentId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/comment/${commentId}`;
        },
        MessageBoardSubscribe: function (orgId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/subscribe`;
        },
        MessageBoardSubscriptions: function (orgId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/subscriptions`;
        },
        MessageBoardSubscription: function (orgId: number, subId: number): string {
            return Routes.LegFiCore.MessageBoardBase(orgId) + `/subscription/${subId}`;
        },

        /** Unit routes ish */
        Units: '/units',
        Unit: function (unitId: number): string {
            return Routes.LegFiCore.Units + '/' + unitId;
        },
        UnitAuxiliaryContacts: function (unitId: number): string {
            return Routes.LegFiCore.Unit(unitId) + '/auxiliary-contacts';
        },
        UnitCharges: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/charges';
        },
        OrganizationUnits: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) + '/units';
        },
        OrganizationUnitsSimple: function (orgId: number): string {
            return Routes.LegFiCore.OrganizationUnits(orgId) + '/simple';
        },
        OrganizationUnit: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.OrganizationUnits(orgId) + '/' + unitId;
        },
        OrganizationUnitBalance: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/balance';
        },
        MemberUnits: function (orgId: number, memberId: number): string {
            return Routes.LegFiCore.Membership(orgId, memberId) + '/units';
        },
        OrganizationUnitImages: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/images';
        },
        OrganizationUnitImage: function (orgId: number, unitId: number, imageId: number): string {
            return Routes.LegFiCore.OrganizationUnitImages(orgId, unitId) + '/' + imageId;
        },
        OrganizationUnitImageCaption: function (orgId: number, unitId: number, imageId: number): string {
            return Routes.LegFiCore.OrganizationUnitImage(orgId, unitId, imageId) + '/caption';
        },
        OrganizationUnitCommLogs: function (orgId: number, unitId: number): string {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/communications/log';
        },
        ParseCsv: function (orgId): string {
            return Routes.LegFiCore.Organization(orgId) + '/csv/parse';
        },
        UnitsBulkImportFileCheck: function (orgId: number): string {
            return Routes.LegFiCore.UnitsBulkImport(orgId) + '/file-check';
        },
        UnitBulkImport: function (orgId: number): string {
            return Routes.LegFiCore.OrganizationUnits(orgId) + '/bulk-import';
        },
        UnitImportResults: function (orgId: number, batchId: string): string {
            return Routes.LegFiCore.UnitBulkImport(orgId) + '/' + batchId;
        },
        UnitMoveMembersIn: function (unitId: number): string {
            return Routes.LegFiCore.Unit(unitId) + '/owners/multiple';
        },
        UnitMoveMembersOut: function (unitId: number): string {
            return Routes.LegFiCore.Unit(unitId) + '/owners/moveOut';
        },
        UnitsBulkImport: function (orgId: number): string {
            return '/organizations/' + orgId + '/units/bulk-import';
        },
        UnitsBulkImportSupportedColumns: function (orgId: number): string {
            return Routes.LegFiCore.UnitsBulkImport(orgId) + '/columns';
        },
        UnitPdf: function (orgId: number, unitId: number, doIncludeAllOccupancies: boolean): string {
            return Routes.LegFiCore.OrganizationUnit(orgId, unitId) + '/' + (doIncludeAllOccupancies ? 1 : 0) + '/pdf';
        },
        RestoreUnit: function (): string {
            return `${Routes.LegFiCore.Units}/restore`;
        },

        Tags: '/tags',
        Tag: function (tagId: number): string {
            return Routes.LegFiCore.Tags + '/' + tagId;
        },
        OrganizationTags: function (organizationId: number): string {
            return Routes.LegFiCore.Organization(organizationId) + '/tags';
        },
        OrganizationTagsByType: function (organizationId: number, type: string): string {
            return Routes.LegFiCore.OrganizationTags(organizationId) + '/' + type;
        },
        OrganizationTagsByTypeAndModel: function (organizationId: number, type: string, modelIds: number[]): string {
            return Routes.LegFiCore.OrganizationTagsByType(organizationId, type) + '?ids=' + modelIds.join(',');
        },
        ManageOrganizationTags: function (organizationId: number): string {
            return Routes.LegFiCore.Organization(organizationId) + '/tags/manage';
        },
        ManageOrganizationTagsByType: function (organizationId: number, type: string): string {
            return Routes.LegFiCore.ManageOrganizationTags(organizationId) + '/' + type;
        },

        // Forms Simplified
        Forms: '/forms',
        FormCounts: '/form-counts',
        Form: function (formId: number): string {
            return `${Routes.LegFiCore.Forms}/${formId}`;
        },
        FormSubmissions: function (formId: number): string {
            return `${Routes.LegFiCore.Forms}/${formId}/submissions`;
        },
        FormApprovers: function (formId: number): string {
            return `${Routes.LegFiCore.Forms}/${formId}/approvers`;
        },
        FormPermissions: function (formId: number): string {
            return `${Routes.LegFiCore.Forms}/${formId}/permissions`;
        },
        FormSubmissionComments: function (formSubmissionId: number): string {
            return `/form-submissions/${formSubmissionId}/comments`;
        },

        Form1099s: '/form-1099s',
        PageAdvertisement: '/page-advertisement',

        SearchKeyGlobal: '/search/key/global',
        SearchKeyOrganizationUnits: '/search/key/organization/units',
    };

    public static VotingModule = {
        OrgBallotAnswers: function (
            orgId: number,
            ballotQuestionId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-questions/' +
                ballotQuestionId +
                '/ballot-answers';
        },
        OrgBallotAnswer: function (
            orgId: number,
            ballotAnswerId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-answers/' +
                ballotAnswerId;
        },
        OrgBallotQuestionAnswer: function (
            orgId: number,
            ballotQuestionId: number,
            ballotAnswerId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-questions/' +
                ballotQuestionId +
                '/ballot-answers/' +
                ballotAnswerId;
        },
        OrgBallotBallotQuestion: function (
            orgId: number,
            ballotId: number,
            ballotQuestionId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballots/' +
                ballotId +
                '/ballot-questions/' +
                ballotQuestionId;
        },
        OrgBallotQuestionDuplicate: function (
            orgId: number,
            ballotQuestionId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-questions/' +
                ballotQuestionId +
                '/duplicate';
        },
        OrgShowBallotQuestion: function (
            orgId: number,
            ballotQuestionId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-questions/' +
                ballotQuestionId;
        },
        OrgBallotQuestions: function (
            orgId: number,
            ballotId: number
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballots/' +
                ballotId +
                '/ballot-questions';
        },
        OrgBallotQuestionTypes: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-question-types';
        },
        OrgBallotQuestionType: function (
            orgId: number,
            ballotQuestionTypeId: number,
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-question-types/' +
                ballotQuestionTypeId;
        },
        OrgBallot: function (orgId: number, ballotId: number): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballots/' +
                ballotId;
        },
        OrgBallots: function (orgId: number): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballots';
        },
        OrgBallotAttachment: function (orgId: number, ballotId: number, attachmentId: number): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballots/' +
                ballotId +
                '/attachments/' +
                attachmentId;
        },
        OrgBallotMemberQuestionAnswers: function (
            orgId: number,
            ballotId: number,
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot/' +
                ballotId +
                '/ballot-member-question-answers';
        },
        OrgBallotMemberQuestionAnswer: function (
            orgId: number,
            ballotMemberQuestionAnswer: number,
        ): string {
            return Routes.LegFiCore.Organization(orgId) +
                '/ballot-member-question-answers/' +
                ballotMemberQuestionAnswer;
        },
    };

    public static MakeVotingModuleUrl(path: string): string {
        return Routes.MakeLegFiCoreUrl('/voting' + path);
    }

    /**
     * @param path
     * @returns {string}
     */
    public static MakeLegFiCoreUrl(path: string) {
        return environment.LegFiCoreApi + path;
    }

    /**
     * @param {number} siteId
     * @returns {string}
     */
    public static GetUiDomain(siteId: number) {
        return environment.PayHoaUi;
    }
}
