import { PaginationMeta } from './pagination';
import { User } from './user';
import { Organization } from './organization';
import { OrganizationProfile } from './organization-profile';
import { BaseAvatar } from './base/avatar';

export class PropertyManagementCompany extends BaseAvatar
{
    id: number;
    membersCount: number;
    organizationsCount: number;
    unitsCount: number;
    users: User[];
    organizations: Organization[];
    orgCityStates: OrganizationProfile[];

    constructor(obj: any) {
        super(obj);

        this.id = obj.id || null;
        this.membersCount = obj.membersCount || null;
        this.organizationsCount = obj.organizationsCount || null;
        this.unitsCount = obj.unitsCount || null;

        if (obj.users) {
            this.users = obj.users.map((u: any) => new User(u));
        }

        if (obj.organizations) {
            this.organizations = obj.organizations.map((o: any) => new Organization(o));
        }

        if (obj.orgCityStates) {
            this.orgCityStates = obj.orgCityStates.map((o: any) => new OrganizationProfile(o));
        }
    }
}

export class PropertyManagementCompanyResponse
{
    data: PropertyManagementCompany[];
    meta: PaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map((c: any) => {
            return new PropertyManagementCompany(c);
        }) : [];
        this.meta = new PaginationMeta(request);
    }
}
