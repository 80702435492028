import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import { COAAccount } from '../../../services/accounting/chart-of-accounts.service';
import Moment = moment.Moment;

export const LIABILITY_ACCOUNT_TYPE = 'liability';

export class Liability implements COAAccount
{
    static readonly INTERNAL_USE_PREPAID_REVENUE = 1;
    static readonly INTERNAL_USE_ACCOUNTS_PAYABLE = 2;

    id: number;
    organizationId: number;
    label: string;
    name: string;
    startingBalance: number;
    //balance: number;
    internalUse: number;
    startingBalanceDate: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    retiredOn: Moment = null;
    reportVisible: boolean;

    old?: any[];
    beingEdited?: boolean = false;
    presentationBalance: number;

    readonly accountType = LIABILITY_ACCOUNT_TYPE;

    constructor(request: any = {}) {
        this.id = request.id || null;
        this.organizationId = request.organizationId || null;
        this.label = request.label || '';
        this.name = request.label || '';
        this.startingBalance = request.startingBalance || 0;
        //this.balance = request.balance || 0;
        this.internalUse = request.internalUse || 0;
        this.presentationBalance = request.startingBalance ? request.startingBalance / 100 : 0;
        this.reportVisible = request.reportVisible || false;

        const timezone = LegFiJwtService.getTimezone();
        this.startingBalanceDate = request.startingBalanceDate ? moment
                .utc(request.startingBalanceDate, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.createdAt = (request.createdAt) ? moment
                .utc(request.createdAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.updatedAt = (request.updatedAt) ? moment
                .utc(request.updatedAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.deletedAt = (request.deletedAt) ? moment
                .utc(request.deletedAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
    }
}
