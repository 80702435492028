import { NgModule } from '@angular/core';
import { appLayoutRouting } from './app-layout.routing';
import { AppLayoutComponent } from './app-layout.component';
import { AppFooterModule } from './shared/app-footer/app-footer.module';
import { HeaderBarModule } from '../shared/header-bar/header-bar.module';
import { PageMenuModule } from './shared/page-menu/page-menu.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ModalModule } from '../shared/modal/modal.module';
import { CommonModule } from '@angular/common';
import { PromoBarModule } from './shared/promo-bar/promo-bar.module';
import { Reminder1099Module } from './shared/reminder-1099/reminder-1099.module';
import { PageAdvertisementModule } from './shared/page-advertisement/page-advertisement.module';
import { CPATaxBannerModule } from './shared/cpatax-banner/cpatax-banner.module';

@NgModule({
    imports: [
        AppFooterModule,
        appLayoutRouting,
        HeaderBarModule,
        PageMenuModule,
        MatSidenavModule,
        ModalModule,
        CommonModule,
        PromoBarModule,
        Reminder1099Module,
        PageAdvertisementModule,
        CPATaxBannerModule,
    ],
    declarations: [
        AppLayoutComponent,
    ],
    exports: [
        AppLayoutComponent,
    ],
})
export class AppLayoutModule
{
}
