import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidenavNavigationComponent } from './sidenav-navigation.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SidenavNavigationItemDirective, SidenavNavigationLoadingDirective } from './sidenav-navigation.directive';
import { SuperStarModule } from '../../../app-layout/shared/super-star/super-star.module';

@NgModule({
    imports: [
        RouterModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        SuperStarModule,
    ],
    declarations: [
        SidenavNavigationComponent,
        SidenavNavigationLoadingDirective,
        SidenavNavigationItemDirective,
    ],
    providers: [],
    exports: [
        SidenavNavigationComponent,
        SidenavNavigationLoadingDirective,
        SidenavNavigationItemDirective,
    ],
})
export class SidenavNavigationModule
{
}
