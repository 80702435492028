import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PHONE_VALIDATION_MASK } from '../directives/validators/string/phone-validator.directive';
import { CA_POSTAL_CODE_VALIDATION_MASK, MX_POSTAL_CODE_VALIDATION_MASK, US_POSTAL_CODE_VALIDATION_MASK } from '../directives/validators/string/postal-code-validator.directive';
import { OrganizationFile } from '../models/entities/organization-file';
import { USER_PASSWORD_SPECIAL_CHARACTERS } from '../directives/validators/string/password-validator.directive';

@Injectable({
    providedIn: 'root',
})
export class ValidationMessageService
{
    /** 20MB total document limit */
    readonly uploadSizeLimit: number = 20971520;

    //noinspection JSMethodCanBeStatic
    getGenericErrorMessage(code: string, errorObj: any) {
        if (typeof errorObj === 'string') {
            return errorObj;
        }

        const config: any = {
            pattern: 'This field contains disallowed characters.',
            required: '* This field is required.',
            requiredSelection: '* This field requires at least one selection.',
            minlength: 'This field is not long enough.',
            maxlength: 'This field is too long.',
            upperCaseLetter: 'This field must contain an uppercase letter.',
            lowerCaseLetter: 'This field must contain a lowercase letter.',
            specialCharacter: 'This field must contain a special character. (ex. ' + USER_PASSWORD_SPECIAL_CHARACTERS + ')',
            int: 'This field must be an integer.',
            decimal: 'This field must be a decimal.',
            numeric: 'This field must be a number.',
            numericLetter: 'This field must contain a number.',
            greaterThan: 'This field\'s value is too small.',
            passwordMismatch: 'Password and confirmation must match.',
            uniqueEmail: 'You must enter a unique email address.',
            email: 'You must enter a valid email address.',
            emailInvalid: 'You must enter a valid email address.',
            emailMatchUser: 'Email address does not match existing user.',
            invalidPhoneFormat: 'You must enter a valid phone number.',
            invalidPostalCodeFormat: 'You must enter a valid zip code.',
            invalidFormat: 'You must enter a valid format for this field.',
            validateEqual: 'Confirmation does not match original.',
            min: 'This field must be greater than ',
            max: 'This field must be less than ',
            maxFileSize: `File size is too large. Attachment should be under 100MB in size.`,
            passwordMatch: 'This field must match the password.',
            vendorMaxAddressCombinedLength: 'Address 1 and Address 2 must be fewer than 50 characters combined.',
            vendorAddress1ShouldContainPOBoxOnly: 'A P.O. Box must be the only value in Address 1 if specified. Please use Address 2 for PMB numbers, or Department/Attention/Mail Stop Code for other delivery details.',
            vendorAddress2ShouldntContainPOBox: 'P.O. Box must be in Address 1.',
            vendorAddress2MustBePMBWhenAddress1ContainsPOBox: 'Address 2 must begin with PMB when Address 1 is a P.O. Box.',
            greaterEndDateInRange: 'Range\'s end date must occur on or after state date.',
            reconciliationDateOverlap: 'Date must not overlap another reconciliation period.',
            invalidAlphaNumericAndSafeSymbols: 'Only A-Z, 0-9, spaces and these symbols: . \' # @ % & / -',
            invalidTINLength: 'This field should contain 9 digits.',
        };

        if (code === 'dateOnOrAfter' && errorObj.hasOwnProperty('date')) {
            return 'This field must be on or after ' + errorObj.date + '.';
        }

        if (code === 'invalidPrefix' && errorObj.hasOwnProperty('prefix')) {
            return 'This field should not begin with ' + errorObj.prefix + '.';
        }

        if (code === 'invalidValue' && errorObj.hasOwnProperty('value')) {
            return 'This field should not be ' + errorObj.value + '.';
        }

        if (code === 'mask' && errorObj.hasOwnProperty('requiredMask')) {
            switch (errorObj.requiredMask) {
                case PHONE_VALIDATION_MASK:
                    // use validation error message for invalidPhoneFormat
                    return config.invalidPhoneFormat;
                case US_POSTAL_CODE_VALIDATION_MASK:
                case MX_POSTAL_CODE_VALIDATION_MASK:
                case CA_POSTAL_CODE_VALIDATION_MASK:
                    // use validation error message for invalidPostalCodeFormat
                    return config.invalidPostalCodeFormat;
                default:
                    // unsure of mask, so use generic invalid format message
                    return config.invalidFormat;
            }
        }

        if (code === 'min' || code === 'max') {
            const amount = Number(errorObj[code]);

            // instead of creating another custom validator for file size,
            // we are masking the max validator and checking for the 20MB
            if (amount === this.uploadSizeLimit) {
                return config.maxFileSize;
            }

            return config[code] + amount.toLocaleString('en-US') + '.';
        }

        if (code === 'pattern' && typeof errorObj !== 'undefined') {
            if (errorObj.hasOwnProperty('requiredPattern') && errorObj.requiredPattern === '^[\\d]+-[\\d]+$') {
                return 'Make sure you include the hyphen character (-) between the two parts of your account number. Example: 17-185191';
            }
        }

        return config.hasOwnProperty(code) ? config[code] : null;
    }

    //noinspection JSMethodCanBeStatic
    /**
     * @param {Response} response
     * @param {string} defaultErrorText optional
     * @returns {string}
     */
    extractResponseErrorText(response: HttpErrorResponse, defaultErrorText?: string): string {
        const errors: string[] = [];

        if (response.error) {
            const responseBody = response.error;
            if (responseBody && responseBody.errors && responseBody.errors.length) {
                for (const e in responseBody.errors) {
                    if (responseBody.errors.hasOwnProperty(e)) {
                        errors.push(responseBody.errors[e].msg);
                    }
                }
            }
        }

        if (errors.length) {
            // Do nothing because the API provided an error message.
        } else if (defaultErrorText) {
            errors.push(defaultErrorText);
        } else {
            errors.push('An unexpected error occurred. Please try again later.');
        }

        return errors.length > 0 ? errors.join('<br />') : null;
    }
}
