import { Component, OnInit } from '@angular/core';
import { OrganizationService, SimpleOrganization } from '../../../../services/organization/organization.service';
import { JwtLegFiClaims } from '../../../../services/auth/jwt-legfi-claims.model';
import { LegFiJwtService } from '../../../../services/auth/legfi-jwt.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Organization } from '../../../../models/entities/organization';
import { Subscription } from '../../../../models/entities/subscription';
import { SubscriptionProduct } from '../../../../models/entities/subscription-product';

@UntilDestroy()
@Component({
  selector: 'app-cpatax-banner',
  templateUrl: './cpatax-banner.component.html',
  styleUrls: ['./cpatax-banner.component.scss']
})
export class CPATaxBannerComponent implements OnInit {

    isShowing = false;
    adminOrganizations: SimpleOrganization[] = [];
    organization: Organization;
    subscriptions: Subscription[] = [];

    constructor(
            private _organizationService: OrganizationService,
    ) {
    }

    get fiscalStart(): Date {
        return new Date(`${new Date().getFullYear()}-${this.organization.profile.fiscalPeriodStart}-01`);
    }

    get deadlineDate(): string {
        return new Date(this.fiscalStart.setMonth(this.fiscalStart.getMonth() + 3, 15)).toLocaleDateString('en-us', {month: 'numeric', day: 'numeric'});
    }

    get isOnTrial(): boolean {
        return this.subscriptions.length > 0;
    }

    get dateInFiscalRange() {
        const currentDate = new Date().getTime();
        return currentDate >= new Date(this.fiscalStart.setMonth(this.fiscalStart.getMonth() - 1)).getTime() && currentDate <= new Date(this.fiscalStart.setMonth(this.fiscalStart.getMonth() + 4)).getTime();
    }

    get isPMC(): boolean {
        return this.adminOrganizations.length > 1;
    }

    ngOnInit() {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if ((jwt.admin || jwt.superUser) && jwt.orgId !== 3582) {
            this.orgHasSubscription(jwt.orgId, jwt.id);
        }
    }

    close() {
        this.isShowing = false;
    }

    protected orgHasSubscription(orgId: number, adminId: number) {
        this._organizationService.getOrganizationById(orgId, [
            'subscriptions',
        ]).pipe(untilDestroyed(this)).subscribe({
            next: (org: Organization) => {
                this.organization = org;
                if (!org.subscriptions.length) {
                    return;
                }
                this.subscriptions = org.subscriptions.filter((sub: Subscription) => {
                    const prod = sub.subscriptionProducts.filter((product: SubscriptionProduct) => product.productId === 4);
                    if (prod) {
                        return sub.lastBilling === null;
                    }
                    return false;
                });
                this.adminHasMultipleMemberships(adminId);
            }
        });
    }

    protected adminHasMultipleMemberships(adminId: number) {
        return this._organizationService.getSimpleOrgList(adminId).pipe(untilDestroyed(this)).subscribe({
            next: (organizations: SimpleOrganization[]) => {
                this.adminOrganizations = organizations;
                // hide during 1099 season
                this.isShowing = !this.isOnTrial && !this.isPMC && this.dateInFiscalRange;
            }
        });
    }
}
