import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({name: 'legfiCurrency'})
export class LegfiCurrencyPipe implements PipeTransform
{
    public transform(
            value: any,
            currencyCode: string = 'usd',
            symbolDisplay: 'code' | 'symbol' | 'symbol-narrow' | boolean = 'symbol',
            digits: string = '.2',
            showCurrencyCode?: boolean,
    ) {
        if (typeof currencyCode !== 'undefined' && currencyCode.length > 0) {
            // LegFi handles USD in cents, not dollars. This is an adjustment for that.
            if (currencyCode.toLocaleLowerCase() === 'usd') {
                value = (parseFloat(value) / 100);
            }

            let currencyString = '';
            if (showCurrencyCode) {
                currencyString = ' <span class="currency">' + currencyCode.toLocaleUpperCase() + '</span>';
            }

            if (typeof symbolDisplay === 'boolean') {
                symbolDisplay = 'symbol';
            }

            currencyCode = currencyCode.toLocaleUpperCase();

            return (new CurrencyPipe('en-US')).transform(value, currencyCode, symbolDisplay, digits) + currencyString;
        }
    }
}
