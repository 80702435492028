import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import environment from '../../../../environments/environment';
import { IdleTimeoutService } from '../../../services/idle-timeout.service';

export interface IdleTimeoutDialogInput
{
    countdown$: Observable<number>;
}

export interface IdleTimeoutDialogOutput {
    keepAlive: boolean;
    preserveLocation: boolean;
}

@Component({
    selector: 'app-idle-timeout-dialog',
    templateUrl: './idle-timeout-dialog.component.html',
})
export class IdleTimeoutDialogComponent implements OnInit
{
    constructor(
            private _idle: IdleTimeoutService,
            private _matDialogRef: MatDialogRef<IdleTimeoutDialogComponent>,
            @Inject(MAT_DIALOG_DATA) public data: IdleTimeoutDialogInput,
    ) {
    }

    ngOnInit() {
        if (this._idle.currentValue > environment.IdleTimeoutThreshold || this._idle.currentValue < 0) {
            // outside valid countdown state for idleTimeout, automatically log out
            const output: IdleTimeoutDialogOutput = {
                keepAlive: false,
                preserveLocation: true,
            };
            this._matDialogRef.close(output);
        }
    }

    closeAndConfirm() {
        const output: IdleTimeoutDialogOutput = {
            keepAlive: true,
            preserveLocation: false,
        };

        this._matDialogRef.close(output);
    }
}
