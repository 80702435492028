import { Moment } from 'moment';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';

export class StripeRefund
{
    id: number;
    stripeRefundId: string;
    paymentAttemptId: number;
    transactionId: number;
    amount: number;
    createdAt: Moment;

    constructor(refund: any) {
        this.id = refund.id || null;
        this.stripeRefundId = refund.stripeRefundId || null;
        this.paymentAttemptId = refund.paymentAttemptId || null;
        this.transactionId = refund.transactionId || null;
        this.amount = refund.amount || null;

        const timezone = LegFiJwtService.getTimezone();
        if (refund.transactionDate) {
            this.createdAt = moment.utc(refund.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
    }
}
